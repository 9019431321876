import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {},
  typography: {
    fontFamily: "Sarabun",
    h1: {
      fontSize: "38px",
      fontWeight: 800,
      fontFamily: "prompt",
      color: "white",
    },
    h2: {
      fontSize: "24px",
      fontWeight: 600,
      fontFamily: "prompt",
      color: "white",
    },
    h3: {
      fontSize: "20px",
      fontWeight: 600,
    },
    h4: {
      fontSize: "18px",
      fontWeight: 500,
      fontFamily: "prompt",
      color: "white",
    },
    h5: {
      fontSize: "16px",
      fontWeight: 400,
      fontFamily: "Sarabun",
      color: "white",
    },
    h6: {},
    subtitle1: {
      fontSize: "18px",
      fontWeight: 600,
      fontFamily: "prompt",
      color: "white",
    },
    subtitle2: {},
  },
  spacing: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40],
});
