import BaccaratContainer from "../components/main-page/BaccaratContainer";
import SecondContainer from "../components/main-page/SecondContainer";
import ThirdContainer from "../components/main-page/ThirdContainer";
import TopContainer from "../components/main-page/TopContainer";
import MenubarMobile from "../components/menubar-mobile/MenubarMobile";

function HomePage() {
  return (
    <>
      <TopContainer />
      <SecondContainer />
      <ThirdContainer />
      <BaccaratContainer />
      <MenubarMobile />
    </>
  );
}

export default HomePage;
