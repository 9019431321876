import { Route, Routes } from "react-router-dom";
import Layout from "../components/layout/Layout";
import BlogList from "../pages/BlogList";
import HomePage from "../pages/HomePage";

function Router() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="" element={<HomePage />} />
        <Route path="blog" element={<BlogList />} />
        <Route path="blog/:blogId" element={<></>} />
      </Route>
    </Routes>
  );
}

export default Router;
