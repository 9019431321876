import { Box, Container, Stack, Typography } from "@mui/material";
import header from "../../assets/blog/blog-header-word.png";
import headerMobile from "../../assets/blog/blog-header-word-m.png";
import background from "../../assets/background/top-body.png";

const styles = {
  mobile: {
    display: { xs: "block", md: "none" },
  },
  pc: {
    display: { xs: "none", md: "block" },
  },
  container: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: { xs: "200px", md: "220px" },
  },
  containerItem: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  containerItemBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    paddingTop: { xs: "16px", md: "0px" },
  },
};

function BlogHeader() {
  return (
    <Box sx={styles.container}>
      <Container sx={styles.mobile} maxWidth="xs">
        <Box sx={styles.containerItemBody}>
          <Box component="img" src={headerMobile} alt="blog-header" />
          <Typography variant="h4" color="white" textAlign="center">
            pgslot เว็บใหม่ สล็อตออนไลน์ pgslot เว็บ หลัก
            ต้องเล่นค่ายเกมที่มาแรงที่สุดในประเทศไทย PG SLOT
          </Typography>
        </Box>
      </Container>

      <Stack sx={styles.pc}>
        <Container maxWidth="lg" sx={styles.containerItem}>
          <Box sx={styles.containerItemBody}>
            <Box
              component="img"
              src={header}
              alt="blog-header"
              sx={{ marginTop: "48px" }}
            />
            <Typography variant="h4" color="white">
              pg slot เว็บใหม่ สล็อตออนไลน์ pg slot เว็บ หลัก
              ต้องเล่นค่ายเกมที่มาแรงที่สุดในประเทศไทย PG SLOT
            </Typography>
          </Box>
        </Container>
      </Stack>
    </Box>
  );
}

export default BlogHeader;
