import { Box, Grid, Stack, Typography } from "@mui/material";
import cardLogo from "../../assets/blog/mock-card.png";

const styles = {
  card: {
    color: "white",
  },
  image: {
    width: "100%",
    height: { xs: "100px", md: "100%" },
    objectFit: "cover",
  },
};

function CardList({ src }) {
  return (
    <Grid xs={5.6} md={3.6} sx={styles.card}>
      <Stack spacing={3}>
        <Box
          component="img"
          sx={styles.image}
          src={src || cardLogo}
          alt="card-logo"
        />
        <Box>
          <Typography variant="h5" color="#F0C37F">
            UPDATE : 29-Oct-2022 03:33
          </Typography>
          <Typography variant="h4" color="white">
            Trick Or Treat - หลอกแหลก แจกไม่อั้น!
          </Typography>
        </Box>
      </Stack>
    </Grid>
  );
}

export default CardList;
