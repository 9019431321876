import { Box, Container, Stack, Typography } from "@mui/material";
import background from "../../assets/background/middle-body.png";
import pgSlotHeader from "../../assets/logo/pgslot-tophit.png";
import cardFortune from "../../assets/card/card-third-1.png";
import cardAztec from "../../assets/card/card-third-2.png";
import cardMahjong from "../../assets/card/card-third-3.png";
import cardCocktail from "../../assets/card/card-third-4.png";
import cardDragon from "../../assets/card/card-third-5.png";
import pgSlotHeaderPc from "../../assets/logo/pgslot-tophit-pc.png";
import cardFortunePc from "../../assets/card/card-top-slot-1.png";
import cardAztecPc from "../../assets/card/card-top-slot-2.png";
import cardMahjongPc from "../../assets/card/card-top-slot-3.png";
import cardCocktailPc from "../../assets/card/card-top-slot-4.png";
import cardDragonPc from "../../assets/card/card-top-slot-5.png";

const styles = {
  mobile: {
    display: { xs: "block", md: "none" },
  },
  pc: {
    display: { xs: "none", md: "block" },
  },
  container: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: { xs: "2320px", md: "840px" },
  },
  cardLayout: {
    borderRadius: "16px",
    cursor: "pointer",
  },
};

function ThirdContainer() {
  return (
    <Box sx={styles.container}>
      <Container sx={styles.mobile} maxWidth="xs">
        <Stack sx={{ paddingY: "20px" }} alignItems="center" spacing={3}>
          <Box component="img" src={pgSlotHeader} alt="pgslot-header" />
          <Typography sx={{ color: "#EBD998" }} variant="h4">
            เกมสล็อตแตกง่าย 2022
          </Typography>
          <Stack alignItems="center">
            <Typography variant="h5">เกมยอดฮิตจาก เกมส์PG SLOT</Typography>
            <Typography variant="h5">
              ค่ายเกมสล็อตชื่อดังที่มีคนเล่นอย่างมาก เกมเยอะ
            </Typography>
            <Typography variant="h5">
              คนเล่นมากแต่มีแค่ไม่กี่เกมที่คนส่วนใหญ่สนใจเล่น
            </Typography>
            <Typography variant="h5">
              มากกว่าคนอื่นเป็นเพราะเป็นเกมสล็อตแตกง่าย
            </Typography>
            <Typography variant="h5">
              ที่ทุกคนเชื่อถือได้จากการจัดอันดับ 5 อันดับเกมฮิต
            </Typography>
            <Typography variant="h5">
              ที่ได้อันดับการเล่นสูงสุดแล้วได้รับรางวัลกลับไปมาก
            </Typography>
            <Typography variant="h5">
              เชื่อได้ว่าทุกคนต้องให้ความสนใจอย่างแน่นอน
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={6}>
          <Box
            sx={styles.cardLayout}
            component="img"
            src={cardFortune}
            alt="card-fortune"
          />
          <Box
            sx={styles.cardLayout}
            component="img"
            src={cardAztec}
            alt="card-aztec"
          />
          <Box
            sx={styles.cardLayout}
            component="img"
            src={cardMahjong}
            alt="card-mahjong"
          />
          <Box
            sx={styles.cardLayout}
            component="img"
            src={cardCocktail}
            alt="card-cocktail"
          />
          <Box
            sx={styles.cardLayout}
            component="img"
            src={cardDragon}
            alt="card-dragon"
          />
        </Stack>
      </Container>

      <Stack sx={styles.pc}>
        <Container sx={{ paddingTop: "40px" }} maxWidth="lg">
          <Stack alignItems="center" spacing={3}>
            <Box component="img" src={pgSlotHeaderPc} alt="pgslot-header" />
            <Typography color="#EBD998" variant="h1">
              เกมสล็อตแตกง่าย 2022
            </Typography>
            <Typography variant="h4">
              เกมยอดฮิตจาก เกมส์PG SLOT ค่ายเกมสล็อตชื่อดังที่มีคนเล่นอย่างมาก
              เกมเยอะ
            </Typography>
            <Typography variant="h4">
              คนเล่นมากแต่มีแค่ไม่กี่เกมที่คนส่วนใหญ่สนใจเล่นมากกว่าคนอื่นเป็นเพราะเป็นเกมสล็อตแตกง่ายที่ทุกคนเชื่อถือได้
            </Typography>
            <Typography variant="h4">
              จากการจัดอันดับ 5 อันดับเกมฮิตPGSLOTS
              ที่ได้อันดับการเล่นสูงสุดแล้วได้รับรางวัลกลับไปมากที่สุด
            </Typography>
          </Stack>
          <Stack
            sx={{ marginY: "24px", overflowX: "hidden" }}
            direction="row"
            spacing={6}
          >
            <Box
              sx={styles.cardLayout}
              component="img"
              src={cardFortunePc}
              alt="card-fortune"
            />
            <Box
              sx={styles.cardLayout}
              component="img"
              src={cardAztecPc}
              alt="card-aztec"
            />
            <Box
              sx={styles.cardLayout}
              component="img"
              src={cardMahjongPc}
              alt="card-mahjong"
            />
            <Box
              sx={styles.cardLayout}
              component="img"
              src={cardCocktailPc}
              alt="card-cocktail"
            />
            <Box
              sx={styles.cardLayout}
              component="img"
              src={cardDragonPc}
              alt="card-dragon"
            />
          </Stack>
        </Container>
      </Stack>
    </Box>
  );
}

export default ThirdContainer;
