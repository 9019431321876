import BlogContent from "../components/blog/BlogContent";
import BlogHeader from "../components/blog/BlogHeader";
import MenubarMobile from "../components/menubar-mobile/MenubarMobile";

function BlogList() {
  return (
    <>
      <BlogHeader />
      <BlogContent />
      <MenubarMobile />
    </>
  );
}

export default BlogList;
