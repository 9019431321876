import { Box, Button } from "@mui/material";
import { lineQrCodePath, registerPath } from "../../config/link";
import footer from "../../assets/footer/footer.png";
import { useNavigate } from "react-router-dom";

const styles = {
  menubar: {
    backgroundImage: `url(${footer})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "112px",
    width: "100%",
    display: { xs: "flex", sm: "none" },
    justifyContent: "space-around",
    alignItems: "center",
    position: "fixed",
    bottom: "0",
  },
};

function MenubarMobile() {
  const navigate = useNavigate();

  return (
    <Box sx={styles.menubar}>
      <Button onClick={() => navigate("/")}></Button>
      <Button onClick={() => navigate("/blog")}></Button>
      <a href={registerPath}>
        <Button></Button>
      </a>
      <a href={lineQrCodePath}>
        <Button></Button>
      </a>
    </Box>
  );
}

export default MenubarMobile;
