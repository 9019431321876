import { Box, Container, Grid, Stack } from "@mui/material";
import background from "../../assets/background/middle-body.png";
import CardList from "./CardList";

const styles = {
  mobile: {
    display: { xs: "block", md: "none" },
  },
  pc: {
    display: { xs: "none", md: "block" },
  },
  container: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: { xs: "1040px", md: "720px " },
    paddingY: { xs: "12px", md: "60px" },
  },
  containerItem: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
};

const mockArrayMobile = [0, 1, 2, 3, 4, 5, 6, 7];
const mockArrayPc = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

function BlogContent() {
  return (
    <Box sx={styles.container}>
      <Container sx={styles.mobile} maxWidth="xs">
        <Grid container gap={3} justifyContent="center">
          {mockArrayMobile.map((el) => {
            return <CardList key={el} />;
          })}
        </Grid>
      </Container>

      <Stack sx={styles.pc}>
        <Container maxWidth="lg" sx={styles.containerItem}>
          <Grid container gap={6}>
            {mockArrayPc.map((el) => {
              return <CardList key={el} />;
            })}
          </Grid>
        </Container>
      </Stack>
    </Box>
  );
}

export default BlogContent;
