import { Box, Container, Stack, Typography } from "@mui/material";
import { gamePath } from "../../config/link";
import happy88Logo from "../../assets/logo/happy88-logo.svg";
import background from "../../assets/background/top-body.png";
import pharaohBackground from "../../assets/background/pharaoh-top-body.png";
import redButton from "../../assets/button/red-button.png";
import topBodyLogo from "../../assets/logo/home-top-body.svg";
import heroPharaoh from "../../assets/background/pharaoh-top-body.png";

const styles = {
  mobile: {
    display: { xs: "block", md: "none" },
  },
  pc: {
    display: { xs: "none", md: "block" },
  },
  flexContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "24px",
    paddingTop: "24px",
  },
  container: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: { xs: "900px", md: "680px" },
  },
  button: {
    "&:hover": {
      cursor: "pointer",
      opacity: 0.8,
    },
  },
  pharaoh: {
    marginTop: { xs: "-24px", sm: "0" },
    mx: "auto",
    objectFit: "cover",
    width: "100%",
    height: "500px",
  },
  leftBox: {
    maxWidth: "40%",
    color: "#fff",
  },
  rightBox: {
    maxWidth: "40%",
    color: "#fff",
    textAlign: "right",
  },
  containerItem: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
};

function TopContainer() {
  return (
    <Box sx={styles.container}>
      <Container sx={styles.mobile} maxWidth="xs">
        <Box sx={styles.flexContainer}>
          <Box component="img" src={happy88Logo} alt="happy88-logo" />
          <Stack spacing={4}>
            <Stack alignItems="center">
              <Typography variant="h1">HAPPY 88</Typography>
              <Typography variant="h1">คาสิโนออนไลน์</Typography>
            </Stack>
            <Stack spacing={1} alignItems="flex-start">
              <Typography variant="h4">
                เว็บอันดับ 1 ของประเทศไทย PGSlot
              </Typography>
              <Typography variant="h4">
                ค่ายเกมอันดับหนึ่ง ของประเทศไทย
              </Typography>
              <Typography variant="h4"></Typography>
              <Typography variant="h4">
                เราคือเว็บสล็อตที่เปิดให้บริการไม่ผ่าน
              </Typography>
              <Typography variant="h4">
                เอเยนต์ลิขสิทธิ์แท้ เป็นเว็บตรงที่เดียว
              </Typography>
              <Typography variant="h4">
                ถือเป็นเว็บสล็อตเว็บใหญ่มาแรงแห่งปี
              </Typography>
            </Stack>
          </Stack>
          <a href={gamePath}>
            <Box
              sx={styles.button}
              component="img"
              src={redButton}
              alt="red button"
            />
          </a>
        </Box>
        <Box
          sx={styles.pharaoh}
          component="img"
          src={pharaohBackground}
          alt="pharaoh-background"
        />
      </Container>

      <Stack sx={styles.pc}>
        <Container maxWidth="lg" sx={styles.containerItem}>
          <Box sx={styles.leftBox}>
            <Box
              component="img"
              sx={{ marginY: "12px" }}
              src={topBodyLogo}
              alt="top-body-logo"
            />
            <Stack spacing={3}>
              <Box>
                <Typography variant="h1">HAPPY 88 ทางเข้าเล่น</Typography>
                <Typography variant="h1">คาสิโนออนไลน์ที่ดีที่สุด</Typography>
              </Box>
              <Typography variant="h4">
                เว็บอันดับ 1 ของประเทศไทย PGSlot ค่ายเกมอันดับหนึ่ง ของประเทศไทย
                เราคือเว็บสล็อตที่เปิดให้บริการโดยไม่ผ่านเอเยนต์ ลิขสิทธิ์แท้
                เป็นเว็บตรง เว็บเดียว ถือเป็นเว็บสล็อต เว็บใหญ่มาแรงแห่งปี.
              </Typography>
              <Box>
                <Box
                  sx={styles.button}
                  component="img"
                  src={redButton}
                  alt="red-button"
                />
              </Box>
            </Stack>
          </Box>
          <Box sx={styles.rightBox}>
            <Box
              sx={{ objectFit: "cover", width: "100%", height: "660px" }}
              component="img"
              src={heroPharaoh}
              alt="hero-pharaoh"
            />
          </Box>
        </Container>
      </Stack>
    </Box>
  );
}

export default TopContainer;
