import { Box, Container, Stack, Typography } from "@mui/material";
import headerLogo from "../../../assets/logo/header-logo.svg";
import lineButton from "../../../assets/button/line-header.png";
import { gamePath, lineQrCodePath } from "../../../config/link";
import { useNavigate } from "react-router-dom";

const styles = {
  typography: {
    cursor: "pointer",
    "&:hover": {
      color: "#F0C37F",
    },
  },
  header: {
    background: "linear-gradient(180deg, #1F1900 0%, #090000 100%)",
    marginY: "auto",
    display: { xs: "none", md: "block" },
  },
  boxSize: {
    width: "33%",
  },
};

function Header() {
  const navigate = useNavigate();

  return (
    <Box sx={styles.header}>
      <Container maxWidth="lg">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            height: "80px",
            paddingX: "48px",
          }}
        >
          <Stack sx={styles.boxSize} direction="row" spacing={5}>
            <Typography
              onClick={() => navigate("/")}
              sx={styles.typography}
              variant="subtitle1"
            >
              หน้าแรก
            </Typography>
            <Typography
              onClick={() => navigate("/blog")}
              sx={styles.typography}
              variant="subtitle1"
            >
              บทความ
            </Typography>
          </Stack>

          <Box sx={styles.boxSize}>
            <Box component="img" src={headerLogo} alt="header-logo" />
          </Box>

          <Stack
            sx={styles.boxSize}
            direction="row"
            spacing={5}
            alignItems="center"
          >
            <a href={gamePath}>
              <Typography sx={styles.typography} variant="subtitle1">
                เข้าสู่เกม
              </Typography>
            </a>

            <a href={lineQrCodePath}>
              <Box
                sx={{
                  height: "50px",
                  "&:hover": {
                    opacity: 0.8,
                  },
                  my: "auto",
                }}
                component="img"
                src={lineButton}
                alt="line-button"
              />
            </a>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}

export default Header;
