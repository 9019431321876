import { Box, Container, Stack, Typography } from "@mui/material";
import background from "../../assets/background/middle-body.png";
import baccaratHeader from "../../assets/logo/baccarat-hot.png";
import baccaratHeaderPc from "../../assets/logo/baccarat-hot-pc.png";
import cardSexy from "../../assets/card/card-baccarat-1.png";
import cardSaGame from "../../assets/card/card-baccarat-2.png";

const styles = {
  mobile: {
    display: { xs: "block", md: "none" },
  },
  pc: {
    display: { xs: "none", md: "block" },
  },
  container: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: { xs: "880px", md: "500px" },
  },
};

function BaccaratContainer() {
  return (
    <Box sx={styles.container}>
      <Container sx={styles.mobile} maxWidth="xs">
        <Stack sx={{ paddingY: "16px" }} alignItems="center">
          <Box
            sx={{ width: "100%" }}
            component="img"
            src={baccaratHeader}
            alt="baccarat-header"
          />
          <Stack alignItems="center">
            <Typography variant="h5">
              เว็บคาสิโน ข้อเสนอที่ดีที่สุดในประเทศไทยแห่งใหม่
            </Typography>
            <Typography variant="h5">
              คอลเลคชั่นเกมมากมาย ลงทะเบียนตอนนี้
            </Typography>
            <Typography variant="h5">
              ไม่มีการดาวน์โหลด สําหรับเดสก์ท็อปและมือถือทั้งหมด
            </Typography>
            <Typography variant="h5">
              เกมที่ปลอดภัยและท้าทาย ลงทะเบียนตอนนี้
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={6}>
          <Stack>
            <Box component="img" src={cardSexy} alt="card-sexy" />
            <Typography sx={{ mx: "auto" }} variant="h4">
              Sexy Baccarat
            </Typography>
          </Stack>
          <Stack>
            <Box component="img" src={cardSaGame} alt="card-sa-game" />
            <Typography sx={{ mx: "auto" }} variant="h4">
              SA Game
            </Typography>
          </Stack>
        </Stack>
      </Container>

      <Stack sx={styles.pc}>
        <Container maxWidth="lg" sx={{ paddingTop: "36px" }}>
          <Stack spacing={6} alignItems="center">
            <Stack alignItems="center">
              <Box
                sx={{ width: "100%" }}
                component="img"
                src={baccaratHeaderPc}
                alt="baccarat-header"
              />
              <Typography variant="h5">
                เว็บคาสิโน ข้อเสนอที่ดีที่สุดในประเทศไทย ใหม่ คอลเลกชันเกม
                ลงทะเบียนตอนนี้
              </Typography>
              <Typography variant="h5">
                ไม่มีการดาวน์โหลด สําหรับเดสก์ท็อปและมือถือทั้งหมด
                เกมที่ปลอดภัยและตลก ลงทะเบียนตอนนี้
              </Typography>
            </Stack>

            <Stack direction="row" spacing={6}>
              <Box>
                <Box component="img" src={cardSexy} alt="card-sexy" />
                <Typography sx={{ mx: "auto" }} variant="h4">
                  Sexy Baccarat
                </Typography>
              </Box>
              <Box>
                <Box component="img" src={cardSaGame} alt="card-sa-game" />
                <Typography sx={{ mx: "auto" }} variant="h4">
                  SA Game
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </Box>
  );
}

export default BaccaratContainer;
