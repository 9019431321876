import { Box, Container, Stack, Typography } from "@mui/material";
import { gamePath, lineQrCodePath } from "../../config/link";
import background from "../../assets/background/middle-body.png";
import lineButtonLong from "../../assets/button/line-button-long.png";
import redButtonLong from "../../assets/button/red-button-long.png";
import cardLady from "../../assets/card/card-middle-1.png";
import cardRat from "../../assets/card/card-middle-2.png";
import cardMonkey from "../../assets/card/card-middle-3.png";
import happy88LogoMiddle from "../../assets/logo/logo-mobile.png";
import happy88Logo from "../../assets/logo/happy88.png";
import redButton from "../../assets/button/red-button.png";
import lineButton from "../../assets/button/line-button.png";

const styles = {
  mobile: {
    display: { xs: "block", md: "none" },
  },
  pc: {
    display: { xs: "none", md: "block" },
  },
  containerItem: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  container: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: { xs: "1312px", md: "740px" },
  },
  menubarButton: {
    width: "80px",
    height: "90px",
  },
  flexContainerMiddle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingX: "16px",
  },
  buttonLine: {
    objectFit: "cover",
    width: "352px",
    height: "100%",
    "&:hover": {
      cursor: "pointer",
      opacity: 0.8,
    },
  },
  buttonGame: {
    objectFit: "cover",
    width: "372px",
    height: "100%",
    "&:hover": {
      cursor: "pointer",
      opacity: 0.8,
    },
  },
  leftBox: {
    color: "#fff",
    textAlign: "left",
  },
  rightBox: {
    maxWidth: "40%",
    color: "#fff",
    my: "auto",
  },
  buttonLinePc: {
    objectFit: "cover",
    width: "160px",
    height: "100%",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  buttonGamePc: {
    objectFit: "cover",
    width: "190px",
    height: "100%",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
};

function SecondContainer() {
  return (
    <Box sx={styles.container}>
      <Container sx={styles.mobile} maxWidth="xs">
        <Box sx={styles.flexContainerMiddle}>
          <Box sx={{ marginTop: "80px" }}>
            <Box
              sx={{ objectFit: "cover", width: "100%", height: "100%" }}
              component="img"
              src={cardLady}
              alt="card-lady"
            />
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{ objectFit: "cover", width: "100%", height: "250px" }}
              component="img"
              src={cardRat}
              alt="card-rat"
            />
            <Box
              sx={{ objectFit: "cover", width: "100%", height: "250px" }}
              component="img"
              src={cardMonkey}
              alt="card-monkey"
            />
          </Box>
          <Stack sx={{ marginBottom: { xs: "36px" } }} spacing={8}>
            <Box component="img" src={happy88LogoMiddle} alt="happy88-logo" />
            <Stack spacing={2}>
              <Typography sx={{ color: "#EBD998" }} variant="h4">
                คาสิโนออนไลน์ (Casino Online) HAPPY88
              </Typography>
              <Typography variant="h5">
                คาสิโนออนไลน์ แฮปปี้ลุค เกมส์ออนไลน์คาสิโนตัวจริง
                เราเชื่อว่าหลายๆท่านคงมีปัญหาที่เกิดความกลัวกับปัญหาในการใช้บริการเว็บพนันที่ให้บริการการเล่นคาสิโนออนไลน์
                เช่นปัญหาการโดนโกง กลัวปิดเว็บหนีไม่จ่ายเงิน
                หรือปัญหาการใช้งานหน้าเว็บในด้านใดๆก็ตาม
                เราอยากจะบอกว่าปัญหาทั้งหลายเหล่านี้ที่ท่านกังวลอยู่นั้น
                ท่านไม่จำเป็นที่จะต้องกลัวอีกต่อไปแล้ว
              </Typography>
            </Stack>
            <Stack spacing={2}>
              <Typography sx={{ color: "#EBD998" }} variant="h4">
                ทำไมต้องเล่น เว็บพนันออนไลน์ HAPPY88
              </Typography>
              <Typography variant="h5">
                <li>Happy88 เป็นเว็บพนันที่อยู่ในอันดับหนึ่งของประเทศไทย </li>
                <li>
                  ได้รับการสนับสนุนจากนักพัฒนาเว็บพนันที่เก่งที่สุดที่ดีที่สุด
                </li>
                <li>ได้ทำการพัฒนาอย่างต่อเนื่องกันมาหลายปี</li>
                <li>ให้ความสำคัญของสมาชิกทุกท่าน</li>
                <li>พร้อมสามารถเดิมพันได้อย่างเพลิดเพลินไม่ติดขัด</li>
              </Typography>
            </Stack>
          </Stack>
          <Stack alignItems="center">
            <a href={lineQrCodePath}>
              <Box
                sx={styles.buttonLine}
                component="img"
                src={lineButtonLong}
                alt="line-button"
              />
            </a>
            <a href={gamePath}>
              <Box
                sx={styles.buttonGame}
                component="img"
                src={redButtonLong}
                alt="red-button"
              />
            </a>
          </Stack>
        </Box>
      </Container>

      <Stack sx={styles.pc}>
        <Container maxWidth="lg" sx={styles.containerItem}>
          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            <Box sx={styles.leftBox}>
              <Box>
                <Box
                  sx={{ objectFit: "cover", width: "100%", height: "100%" }}
                  component="img"
                  src={cardLady}
                  alt="card-lady"
                />
              </Box>
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{ objectFit: "cover", width: "100%", height: "400px" }}
                  component="img"
                  src={cardRat}
                  alt="card-rat"
                />
                <Box
                  sx={{ objectFit: "cover", width: "100%", height: "400px" }}
                  component="img"
                  src={cardMonkey}
                  alt="card-monkey"
                />
              </Box>
            </Box>
            <Box sx={styles.rightBox}>
              <Stack sx={{ marginTop: { md: "40px" } }} spacing={4}>
                <Box component="img" src={happy88Logo} alt="happy88-logo" />
                <Box>
                  <Typography
                    sx={{ marginBottom: "8px" }}
                    color="#EBD998"
                    variant="h2"
                  >
                    คาสิโนออนไลน์ (Casino Online) HAPPY88
                  </Typography>
                  <Typography variant="h5">
                    คาสิโนออนไลน์ แฮปปี้ลุค เกมส์ออนไลน์คาสิโนตัวจริง
                    เราเชื่อว่าหลายๆท่านคงมีปัญหาที่เกิดความกลัวกับปัญหาในการใช้บริการเว็บพนันที่ให้บริการการเล่นคาสิโนออนไลน์
                    เช่นปัญหาการโดนโกง กลัวปิดเว็บหนีไม่จ่ายเงิน
                    หรือปัญหาการใช้งานหน้าเว็บในด้านใดๆก็ตาม
                    เราอยากจะบอกว่าปัญหาทั้งหลายเหล่านี้ที่ท่านกังวลอยู่นั้น
                    ท่านไม่จำเป็นที่จะต้องกลัวอีกต่อไปแล้ว
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{ marginY: "8px" }}
                    color="#EBD998"
                    variant="h2"
                  >
                    ทำไมต้องเล่น เว็บพนันออนไลน์ HAPPY88
                  </Typography>
                  <Typography variant="h5">
                    <li>
                      Happy88 เป็นเว็บพนันที่อยู่ในอันดับหนึ่งของประเทศไทย{" "}
                    </li>
                    <li>
                      ได้รับการสนับสนุนจากนักพัฒนาเว็บพนันที่เก่งที่สุดที่ดีที่สุด
                    </li>
                    <li>ได้ทำการพัฒนาอย่างต่อเนื่องกันมาหลายปี</li>
                    <li>ให้ความสำคัญของสมาชิกทุกท่าน</li>
                    <li>พร้อมสามารถเดิมพันได้อย่างเพลิดเพลินไม่ติดขัด</li>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <a href={lineQrCodePath}>
                    <Box
                      sx={styles.buttonLinePc}
                      component="img"
                      src={lineButton}
                      alt=""
                    />
                  </a>
                  <a href={gamePath}>
                    <Box
                      sx={styles.buttonGamePc}
                      component="img"
                      src={redButton}
                      alt=""
                    />
                  </a>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Container>
      </Stack>
    </Box>
  );
}

export default SecondContainer;
